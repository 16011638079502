import React from "react";
import { useNavigate } from "react-router-dom";
import helper from "services/helper";

const LuckydrawItem = ({ item = {} }) => {
  const navigate = useNavigate();
  // item.available = 1000
  const disabled = item.available >= item.people;
  const soldout = disabled ? " soldout" : "";
  return (
    <a
      style={helper.STYLE.aDisable(disabled)}
      onClick={() => navigate("/luckydraw-details?id=" + item.id)}
      className={soldout}
    >
      {/* <!-- ถ้าชิ้นนี้หมดแล้วให้ใส่ Class soldout --> */}
      <div
        className="bPic"
        style={{ backgroundImage: `url(${item.img})` }}
      >
        
      </div>
      <div className="bDetails">
        <div className="tTitle">{item.name}</div>
        <div className="tDes">{item.description}</div>
        <div className="tDate">
          <span>ลุ้นได้ถึงวันที่: </span>
          {helper.TIME.toShortDateNormalTH(item.toDate)}
        </div>
        <div className="bPointQuota">
          <div className="bPoint">
            <span>{helper.FN.toNumberWithCommas(item.pointToLuckyDraw)}</span>
          </div>
          <div className="tQuota">
            จำนวนลุ้น (สิทธิ์)
            <span>{helper.FN.toNumberWithCommas(item.totalTime)}</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default LuckydrawItem;
