import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalEventPointEmpty = ({ isOpen = false, onClose, onConfirm }) => {
  const [modalRef, setModalRef] = useState(null);

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      // listenerConfirmModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalEventPointEmpty"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  // const listenerConfirmModal = () => {
  //   const btnYesModal = document.getElementById("btnYesModalRewardEmpty");
  //   btnYesModal.addEventListener("click", () => {
  //     onConfirm();
  //     closeModal();
  //   });
  // };

  const listenerCloseModal = () => {
    const btnNoModal = document.getElementById("btnModalCancelEvent");    
    btnNoModal.addEventListener("click", () => {
      console.log("test");
      
      closeModal();
    });
   
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    // console.log("Test close");

    modalRef.close();
    onClose();
  };

  return (
    // <!-- Modal Reward Empty -->
    <div
      class="bModal bCard"
      id="ModalEventPointEmpty"
      style={{ display: "none" }}
    >
      <div class="bInner">
        <div class="bDetails">
          <i class="icoAlert"></i>
          <p class="tTitle">คะแนนของคุณไม่เพียงพอ</p>
          <div class="bPic" data-flavor="matcha"></div>
          <p class="tDetails">
            คุณสามารถสแกนสะสมคะแนนจากล่อง
            <br />
            รสริช มัทฉะเพิ่มเพื่อแลกสิทธิ์อีกครั้ง
          </p>
        </div>
        <div class="bBtn">
          <a class="btn red btnModalCancel" id="btnModalCancelEvent">
            เข้าใจแล้ว
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalEventPointEmpty;
