import { get, post, put } from "../Config";

// POST
// export function OtpRequest({ data }) {
//   return post({ url: `/api/v1/customer/otp_request`, data, timeOut: 20000 });
// }
// export function OtpVerify({ data }) {
//   return post({ url: `/api/v1/customer/otp_verify`, data, timeOut: 20000 });
// }

export function AccountAuth({ data }) {
  return post({ url: `/api/v1/customer/account_auth`, data, timeOut: 20000 });
}
export function AccountCreate({ data }) {
  return post({ url: `/api/v1/customer/account_create`, data, timeOut: 20000 });
}
export function AccountUpdate({ data }) {
  return post({ url: `/api/v1/customer/account_update`, data, timeOut: 20000 });
}

// GET
export function CheckIdCardExists({ params }) {
  return get({ url: `/api/v1/customer/idcard_exists`, params });
}
export function CheckAccountExists({ params }) {
  return get({ url: `/api/v1/customer/account_exists`, params });
}
export function AccountSearch({ params }) {
  return get({ url: `/api/v1/customer/account_search`, params });
}

// KOALA API
// GET
export function GetSearchCustomerData({ params }) {
  return get({ url: `/api/koala/v1/customer`, params });
}
export function AddressInfo() {
  return get({ url: `/api/koala/v1/address` });
}
export function QuestionnaireSearch() {
  return get({ url: `/api/koala/v1/questionnaire-main` });
}
export function GetCustomerData({ id }) {
  return get({ url: `/api/koala/v1/customer/${id}` });
}
export function CheckRef({ params }) {
  return get({ url: `/api/koala/v1/check`, params });
}
export function CheckCode({ params }) {
  return get({ url: `/api/koala/v1/check-sku`, params });
}
export function CheckSkuForm({ data }) {
  return post({ url: `/api/koala/v1/check-sku-form`, data, timeOut: 20000 });
}

export function GetPointHistory({ params }) {
  return get({ url: `/api/koala/v1/point-history`, params });
}

export function CheckDupReceipt({ params }) {
  return get({ url: `api/koala/v1/check-receipt`, params });
}

export function GetUploadReceiptSchedual({ params }) {
  return get({ url: `/api/koala/v1/upload-receipt-schedual`, params });
}

// /api/koala/v1/check-sku?codeSku=07290XZNWDXZA&customerId=1

// POST
export function CreateCustomer({ data }) {
  return post({ url: `/api/koala/v1/customer/`, data, timeOut: 20000 });
}
export function CreateQuestion({ data }) {
  return post({
    url: `/api/koala/v1/customer-questionnaire`,
    data,
    timeOut: 20000,
  });
}
export function CreatePointHistory({ data }) {
  return post({ url: `/api/koala/v1/point-history`, data, timeOut: 20000 });
}

export function CreatePointHistoryAndSticker({ data }) {
  return post({ url: `/api/koala/v1/pointHistory`, data, timeOut: 20000 });
}

export function CreateReciept({ data }) {
  return post({
    url: `/api/koala/v1/upload-receipt-history`,
    data,
    timeOut: 20000,
  });
}
export function CreateTransactionCustomer({ data }) {
  return post({ url: `/api/koala/v1/createCustomer`, data, timeOut: 20000 });
}

export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}
export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}

// PUT
export function UpdateCustomer({ data, id }) {
  return put({ url: `/api/koala/v1/customer/${id}`, data, timeOut: 20000 });
}

export function UpdateTransactionCustomer({ data, id }) {
  return put({
    url: `/api/koala/v1/updateCustomer/${id}`,
    data,
    timeOut: 20000,
  });
}
