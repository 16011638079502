import React, { useState, useEffect } from "react";
import moment from "moment";

const CountdownTimer = ({ expTime, callback }) => {
  // ฟังก์ชันคำนวณเวลาที่เหลือ
  const calculateTimeLeft = () => {
    const now = moment();
    const expiration = moment(expTime, "YYYY-MM-DD HH:mm:ss");
    const difference = expiration.diff(now, "seconds");
    return difference > 0 ? difference : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // เมื่อ expTime เปลี่ยนแปลง ให้คำนวณใหม่และรีเซ็ตเวลา
    setTimeLeft(calculateTimeLeft());
  }, [expTime]); // เพิ่ม expTime ใน dependencies

  useEffect(() => {
    // หากหมดเวลา ให้เรียก callback และหยุดทำงาน
    if (timeLeft <= 0) {
      if (callback) callback(); // เรียก callback เมื่อหมดเวลา
      return;
    }

    // ตั้ง interval เพื่ออัปเดตเวลาทุก ๆ วินาที
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft <= 0) {
          clearInterval(timer); // ล้าง interval เมื่อหมดเวลา
          if (callback) callback();
        }
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(timer); // ล้าง interval เมื่อ component ถูกทำลาย
  }, [timeLeft, callback]); // ใช้ timeLeft และ callback เป็น dependencies

  // ฟังก์ชันฟอร์แมตเวลา
  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    const minutes = Math.floor(duration.asMinutes());
    const secs = duration.seconds();
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return <span id="timer">{formatTime(timeLeft)}</span>;
};

export default CountdownTimer;
