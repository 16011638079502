import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Register from "pages/register/register";
import Question from "pages/register/question";
import ThemeSelected from "pages/register/theme";
import RegisterSuccess from "pages/register/success";
import Profile from "pages/profile/Profile";
import EditProfile from "pages/profile/editProfile";
import ThemeProfile from "pages/profile/themeProfile";
import EditProfileForm from "pages/profile/editProfileForm";
import Collectpoint from "pages/scanpoint/collectpoint";
import Reward from "pages/reward/Reward";
import RewardDetail from "pages/reward/RewardDetail";
import RecieptComplete from "pages/scanpoint/recieptComplete";
import RewardHistory from "pages/rewardHistory/RewardHistory";
import RewardHistoryDetail from "pages/rewardHistory/RewardHistoryDetail";
import OCR from "utility/ocr";
import CollectPointHistory from "pages/collectPointHistory/CollectPointHistory";
import RewardConfirm from "pages/reward/RewardConfirm";
import RewardComplete from "pages/reward/RewardComplete";
import Luckydraw from "pages/luckydraw/Luckydraw";
import LuckydrawDetail from "pages/luckydraw/LuckydrawDetail";
import LuckydrawComplete from "pages/luckydraw/LuckydrawComplete";
import LuckydrawHistory from "pages/luckydrawHistory/LuckydrawHistory";
import LuckydrawHistoryDetail from "pages/luckydrawHistory/LuckydrawHistoryDetail";
import ReceiptHistory from "pages/receiptHistory/ReceiptHistory";
import CameraComponent from "utility/openCamera";
import BrightnessAdjustment from "utility/bright";
import ScanPointNew from "pages/scanpoint/ScanpointNew";
import CharacterProcess from "pages/characterSticker/CharacterProcess";
import CharacterPoint from "pages/characterSticker/CharacterPoint";
import CharacterCollection from "pages/characterSticker/CharacterCollection";
import CharacterDetail from "pages/characterSticker/CharacterDetail";
import PillowDetail from "pages/pillow/PillowDetail";
import PillowConfirm from "pages/pillow/PillowConfirm";
import PillowComplete from "pages/pillow/PillowComplete";
import PillowHistory from "pages/pillowHistory/PillowHistory";
import PillowHistoryDetail from "pages/pillowHistory/PillowHistoryDetail";
import EventNews from "pages/eventNews/EventNews";
import EvenNewsExternal from "pages/eventNews/EvenNewsExternal";
import Otp from "pages/register/Otp";

const appRouter = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/register" element={<Register />} />
        <Route path="/otp" element={<Otp />} />
        <Route
          path="/"
          element={
            props.isProfile === true &&
            props.isState.customerId !== null &&
            props.isState.isRegisSbSystem === true ? (
              <Profile />
            ) : (
              <Register />
            )
          }
        />
        <Route path="/question" element={<Question />} />
        <Route path="/theme" element={<ThemeSelected />} />
        <Route path="/success" element={<RegisterSuccess />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile-view" element={<EditProfile />} />
        <Route path="/profile-edit" element={<EditProfileForm />} />
        {/* <Route path="/scan" element={<QrScaner />} /> */}
        <Route path="/profile-theme" element={<ThemeProfile />} />
        <Route path="/collectpoint" element={<Collectpoint />} />
        <Route path="/scanpoint" element={<ScanPointNew />} />
        <Route path="/ocr" element={<OCR />} />
        {/* REWARD PAGE */}
        <Route path="/reward" element={<Reward />} />
        <Route path="/reward-details" element={<RewardDetail />} />
        <Route path="/reward-confirm" element={<RewardConfirm />} />
        <Route path="/reward-complete" element={<RewardComplete />} />
        <Route path="/history-reward" element={<RewardHistory />} />
        <Route
          path="/history-reward-details"
          element={<RewardHistoryDetail />}
        />
        {/* LUCKYDRAW PAGE */}
        <Route path="/luckydraw" element={<Luckydraw />} />
        <Route path="/luckydraw-details" element={<LuckydrawDetail />} />
        <Route path="/luckydraw-complete" element={<LuckydrawComplete />} />
        <Route path="/history-luckydraw" element={<LuckydrawHistory />} />
        <Route
          path="/history-luckydraw-details"
          element={<LuckydrawHistoryDetail />}
        />
        <Route path="/reciept-complete" element={<RecieptComplete />} />
        <Route
          path="/history-collect-point"
          element={<CollectPointHistory />}
        />
        <Route path="/history-receipt" element={<ReceiptHistory />} />
        <Route path="/open-camera" element={<CameraComponent />} />
        {/* <Route path="/bright" element={<BrightnessAdjustment />} /> */}
        {/*STICKER PAGE */}
        <Route path="/character-process" element={<CharacterProcess />} />
        <Route path="/character-point" element={<CharacterPoint />} />
        <Route path="/character-collection" element={<CharacterCollection />} />
        <Route path="/character-detail" element={<CharacterDetail />} />
        {/* Pillow Page */}
        <Route path="/pillow-detail" element={<PillowDetail />} />
        <Route path="/pillow-confirm" element={<PillowConfirm />} />
        <Route path="/pillow-complete" element={<PillowComplete />} />
        <Route path="/history-pillow" element={<PillowHistory />} />
        <Route
          path="/history-pillow-details"
          element={<PillowHistoryDetail />}
        />
        {/* Event News */}
        <Route path="/event-news" element={<EventNews />} />
        <Route path="/line-event-news" element={<EvenNewsExternal />} />
      </Routes>
    </Router>
  );
};

export default appRouter;
