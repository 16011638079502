import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import RewardItem from "./RewardItem";
import Banners from "../centerComponent/Banners";
import { RewardList } from "services/Api/Module/Reward";
import Pagination from "../centerComponent/Pagination";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import Submenu from "pages/centerComponent/Submenu";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;
const Reward = () => {
  const [rewards, setRewards] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getRewardList = async () => {
    let _return = {
      status: false,
      result: null,
      rowCount: 0,
    };

    try {
      const { data } = await RewardList({ params: { limit, offset } });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
        setIsLoad(false);
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchRewardList = async () => {
    try {
      const resData = await getRewardList();
      setRewards(resData.result);
      setRowCount(resData.rowCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRewardList();
  }, [stateProfile, offset]);

  console.log("currentPoint ", stateProfile.currentPoint);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageReward-All">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <CollectpointHeader />
            {!isLoad ? (
              rewards.length ? (
                <section id="pContent">
                  <Submenu />
                  <Banners currentPage="Rewards" />
                  <div className="bTitle">
                    <h2>Rewards</h2>
                    <p>ใช้ Point แลกของที่คุณชอบ</p>
                  </div>
                  <div className="bBtn">
                    <a className="btn ">รางวัลทั้งหมด</a>
                    <a
                      className="btn outline"
                      onClick={() => navigate("/history-reward")}
                    >
                      ประวัติการแลก
                    </a>
                  </div>
                  <div className="bGroupItem">
                    {rewards.map((item, index) => {
                      return <RewardItem key={index} item={item} />;
                    })}
                  </div>
                  <Pagination
                    limit={limit}
                    offset={offset}
                    rowCount={rowCount}
                    onBack={(limit, offset) => {
                      console.log(
                        "🚀 ~ Reward ~ limit, offset:",
                        limit,
                        offset
                      );
                      setSearchParams({ offset });
                    }}
                    onNext={(limit, offset) => {
                      console.log(
                        "🚀 ~ Reward ~ limit, offset:",
                        limit,
                        offset
                      );
                      setSearchParams({ offset });
                    }}
                  />
                </section>
              ) : (
                <section id="pComingSoon">
                  <p>รอติดตาม Reward เร็ว ๆ นี้</p>
                </section>
              )
            ) : (
              ""
            )}
            <Menu />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Reward;
