import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "features/RegisterKlSlice";
import { setTheme } from "features/ThemeSlice";
import {
  CreateTransactionCustomer,
  UpdateTransactionCustomer,
} from "services/Api/Module/Customer";

import ModalContext from "contexts/ModalContext";
import _ from "lodash";
import { setProfile } from "features/ProfileSlice";

const Theme = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateCustomer = useSelector((state) => state.registerKl.customerData);
  const stateQuestion = useSelector((state) => state.registerKl.answerQuestion);
  const stateTheme = useSelector((state) => state.themeKl.theme);
  const stateAllQuestion = useSelector((state) => state.registerKl.allQuestion);

  const stateProfile = useSelector((state) => state.profile);
  const [isLoading, setIsLoading] = useState(false);

  // const stateRegister = useSelector((state)=>)
  const { mAlert } = useContext(ModalContext);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const customerId = (id) => {
    dispatch(
      setProfile({
        customerId: id,
      })
    );
  };

  useEffect(() => {
    const theme = {
      ...stateCustomer,
      themeSelected: localStorage.getItem("theme"),
    };
    dispatch(setRegister(theme));
  }, [dispatch, stateCustomer]);

  // Prepare Create Customer Data
  const payloadDataCreateCustomer = () => {
    const {
      address,
      birthDate,
      firstName,
      genderId,
      isConsentNewsAndPromotion,
      isConsentPDPA,
      isConsentTAndC,
      lastName,
      mobileNo,
      postcode,
      subDistrict,
      themeSelected,
      lineImageUrl,
      lineUid,
      lineName,
      referenceId,
    } = stateCustomer;

    const convertTheme = themeSelected === "green" ? 1 : 2;
    const pointType = 1;
    const status = 1;
    const _stateAllQuestion = [...stateAllQuestion];
    const _stateAnswerQuestion = { ...stateQuestion };
    let prepareQuestionnaire = new Array();

    let isEmpty = Object.keys(_stateAnswerQuestion).length === 0;
    if (!isEmpty) {
      prepareQuestionnaire = _stateAllQuestion.map((item) => {
        return {
          questionnaireMain: item.questionmain,
          questionnaireList: stateQuestion[item.questionmain],
        };
      });
    }

    const registerPayload = {
      lineUid: lineUid,
      lineName: lineName,
      pProfileImgUrl: lineImageUrl,
      firstName: firstName,
      lastName: lastName,
      mobileNo: mobileNo,
      birthDate: birthDate,
      genderId: genderId,
      address: address,
      referenceId: referenceId ? referenceId : null,
      themeSelected: convertTheme,
      isRegisSbSystem: true,
      subDistrict: subDistrict,
      postcode: postcode,
      isConsentPdpa: isConsentPDPA,
      isConsentTandc: isConsentTAndC,
      isConsentNewsAndPromotion: isConsentNewsAndPromotion,
      pointType: pointType,
      status: status,
      customerQuestionniare: prepareQuestionnaire,
    };

    return registerPayload;
  };
  //Prepare Update Custoemer Data
  const payloadDataUpdateCustomer = () => {
    const {
      customerId,
      address,
      firstName,
      genderId,
      isConsentNewsAndPromotion,
      isConsentPDPA,
      isConsentTAndC,
      lastName,
      mobileNo,
      birthDate,
      postcode,
      referenceId,
      subDistrict,
      themeSelected,
      lineImageUrl,
      lineUid,
      lineName,
    } = stateCustomer;

    const convertTheme = themeSelected === "green" ? 1 : 2;
    const pointType = 1;
    const status = 1;
    const _stateAllQuestion = [...stateAllQuestion];
    const _stateAnswerQuestion = { ...stateQuestion };
    const prepareQuestionnaire = new Array();

    let isEmpty = Object.keys(_stateAnswerQuestion).length === 0;
    if (!isEmpty) {
      prepareQuestionnaire = _stateAllQuestion.map((item) => {
        return {
          questionnaireMain: item.questionmain,
          questionnaireList: stateQuestion[item.questionmain],
        };
      });
    }
    let registerPayload = {
      customerId: customerId,
      lineUid: lineUid,
      lineName: lineName,
      pProfileImgUrl: lineImageUrl,
      firstName: firstName,
      lastName: lastName,
      mobileNo: mobileNo,
      birthDate: birthDate,
      genderId: genderId,
      address: address,
      referenceId: referenceId,
      themeSelected: convertTheme,
      isRegisSbSystem: true,
      subDistrict: subDistrict,
      postcode: postcode,
      isConsentPdpa: isConsentPDPA,
      isConsentTandc: isConsentTAndC,
      isConsentNewsAndPromotion: isConsentNewsAndPromotion,
      pointType: pointType,
      status: status,
      customerQuestionniare: prepareQuestionnaire,
    };

    return registerPayload;
  };

  //Create customer
  const createNewCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await CreateTransactionCustomer({ data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {
            navigate("/register");
          },
        });
      }
    } catch (e) {
      let _title = "เกิดข้อผิดพลาด",
        _subTitle = "กรุณาลองใหม่อีกครั้ง";
      mAlert.info({
        open: true,
        title: _title,
        subTitle: _subTitle,
        content: "",
        onExit: () => {
          navigate("/theme");
        },
      });
      setIsLoading(false);
      console.log("ERR :", e);
    }
    return _return;
  };

  //API Update Customer
  const updateCustomer = async (id, payload) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await UpdateTransactionCustomer({
        id: id,
        data: payload,
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {
            navigate("/register");
          },
        });
      }
    } catch (e) {
      let _title = "เกิดข้อผิดพลาด",
        _subTitle = "กรุณาลองใหม่อีกครั้ง";
      mAlert.info({
        open: true,
        title: _title,
        subTitle: _subTitle,
        content: "",
        onExit: () => {
          navigate("/theme");
        },
      });
      setIsLoading(false);
      console.log("ERR :", e);
    }
    return _return;
  };

  const createCustomerTransaction = async (payload) => {
    const result = await createNewCustomer(payload);
    if (result.status) {
      customerId(result.result.id);
      navigate("/success");
    } else {
      console.log("error");
    }
    return result;
  };

  const updateCustomerTransaction = async (id, payload) => {
    const result = await updateCustomer(id, payload);
    if (result.status) {
      customerId(result.result.id);
      navigate("/success");
    } else {
      console.log("error");
    }
    return result;
  };

  const onSubmit = () => {
    setIsLoading(true);
    const condition = stateCustomer.customerId ? true : false;
    try {
      if (!condition) {
        const payloadCreateData = payloadDataCreateCustomer();
        createCustomerTransaction(payloadCreateData);
      } else if (condition) {
        const payloadUpdateData = payloadDataUpdateCustomer();
        updateCustomerTransaction(stateCustomer.customerId, payloadUpdateData);
      } else {
        console.log("condition is no value.");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERR :", error);
    }
  };

  const selectTheme = (color) => {
    const theme = { ...stateCustomer, themeSelected: color };
    localStorage.setItem("theme", color);
    dispatch(setTheme(color));
    dispatch(setRegister(theme));
  };

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRegister-step3">
        <main>
          <div className="wrapContent">
            <section id="pThemesMain">
              <div className="bThemes">
                <div className="bTitlePage">
                  <h1>เลือกธีม</h1>
                  <p>เลือกธีมคาแร็กเตอร์ที่คุณชอบ</p>
                </div>
                <div className="bForm">
                  <div className="bSelectThemes">
                    <div className="control-group imgRadio">
                      <label className="control control--radio green">
                        <input
                          type="radio"
                          value="green"
                          name="theme"
                          onChange={(e) => selectTheme(e.target.value)}
                          checked={"green" === stateTheme}
                        />
                        <div className="control__indicator">
                          <div className="bPic"></div>
                        </div>
                      </label>
                      <label className="control control--radio pink">
                        <input
                          type="radio"
                          value="pink"
                          name="theme"
                          onChange={(e) => selectTheme(e.target.value)}
                          checked={"pink" === stateTheme}
                        />
                        <div className="control__indicator">
                          <div className="bPic"></div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="bStepBtn">
                  <div className="bStep">
                    <div className="bBullet">
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                    </div>
                    <p>ขั้นตอนสุดท้ายแล้ว เลือกธีมเลย!</p>
                  </div>
                  <div className="bBtn">
                    <button
                      type="button"
                      className="btn"
                      onClick={onSubmit}
                      disabled={isLoading}
                    >
                      ยืนยัน
                    </button>
                    <button
                      className="btn outline"
                      onClick={goBack}
                      disabled={isLoading}
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Theme;
