import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { setProfile } from "features/ProfileSlice";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerData } from "services/Api/Module/Customer";
import Menu from "pages/menuMain/menu";
import Banners from "pages/centerComponent/Banners";
import moment from "moment";

const Collectpoint = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const cusId = stateProfile.customerId;
  const fetchCustomerProfile = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await GetCustomerData({ id: id });

      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };
  useEffect(()=>{
   const fetchProfile = ( async ()=>{
      const custormerProfile = await fetchCustomerProfile(cusId);
      dispatch(
        setProfile({
          ...custormerProfile.result,result:custormerProfile.result
          
        })
      );

      console.log("after-custormerProfile",custormerProfile);
    })
    fetchProfile();
    
  },[])

  // console.log("stateProfile => ", stateProfile);
  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <>
        <div id="pageScan-Collectpoint">
          <main>
            <div className="wrapContent">
              <section id="pPointTop">
                <h1 className="bTitle">Collect points</h1>
                <div className="bPoint">
                  <p>Koala’s March point ของคุณ</p>
                  <div className="bTotalPoint">
                    <span>{stateProfile.currentPoint}</span>
                  </div>
                  <p>
                    หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}
                  </p>
                </div>
              </section>
              <section id="pCollectpoint">
                <div className="bCollectpoint">
                  <div className="bPoint">
                    <p>คุณได้รับ Points</p>
                    <div className="bTotalPoint">
                      <span>{stateProfile.actionPoint}</span>
                    </div>
                  </div>
                  <div className="bLink">
                    <a onClick={() => navigate("/history-collect-point")}>
                      ประวัติการ Collect point
                    </a>
                  </div>
                </div>
              </section>
              <section id="bButton">
                <div className="bBtn">
                  <a onClick={() => navigate("/scanpoint")} className="btn">
                    กรอกโค้ดเพิ่ม
                  </a>
                  <a
                    onClick={() => navigate("/reward")}
                    className="btn outline"
                  >
                    แลกของรางวัล
                  </a>
                </div>
              </section>
              <section id="pBanner">
                <Banners currentPage="Scan results" />
              </section>
              <Menu />

              {/* <!-- Menu -->
          <?php include 'inc/inc-menu.php';?>

          <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
            </div>
          </main>
        </div>
      </>
    </div>
  );
};

export default Collectpoint;
