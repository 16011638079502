import React, { useState, useEffect, useRef } from "react";
import { InputOtp } from "primereact/inputotp";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OtpRequest, OtpVerify } from "services/Api/Module/Customer";
import CountdownTimer from "components/timecoutdown/CountdownTimer";
import moment from "moment";

const Otp = () => {
  const [otpPin, setOtpPin] = useState(null);
  const [isExp, setIsExp] = useState(false);
  const [isTextError, setIsTextError] = useState("");
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [tokenData, setTokenData] = useState({});
  const [verifyResult, setVerifyResult] = useState("");
  const stateCustomer = useSelector((state) => state.registerKl.customerData);
  const [invalidCount, setInvalidCount] = useState(0);
  const [validateStatus, setValidateStatus] = useState("");
  const [isResend, setIsResend] = useState(false);

  const otpRequest = async (mobileNo) => {
    const payload = {
      msisdn: mobileNo,
    };
    let _return = {
      status: false,
      result: [],
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      if (data?.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = data.resultData;
        setTokenData(resData);
        console.log("resData----->", resData);
      }
    } catch (error) {
      console.log("ERR :", error);
    }
  };

  const otpError = document.getElementsByClassName("p-inputotp-input");
  // console.log("invalidCount====>", invalidCount);
  // console.log("otpPin====>", otpPin);

  const otpVerify = async (value) => {
    const payload = {
      token: tokenData.token,
      pin: value,
    };

    if (value.length == 6 && invalidCount < 3) {
      try {
        const result = await OtpVerify({ data: payload });
        if (result?.data?.resultCode == 20000) {
          setVerifyResult(result.data);
          for (let i = 0; i < otpError.length; i++) {
            otpError[i].classList.remove("input-error");
          }
        } else {
          setVerifyResult(result.data);
          for (let i = 0; i < otpError?.length; i++) {
            otpError[i].classList.add("input-error");
          }

          if (result.data?.resultError?.code === "otp_invalid") {
            setInvalidCount((prev) => prev + 1);
          }
        }
      } catch (error) {
        console.log("ERR", error);
      }
    }

    // else {
    //   setVerifyResult("");
    //   console.log("ยังไม่ยิง");
    // }
  };

  const handleValidate = () => {
    if (verifyResult.resultCode == 20000) {
      console.log("20000");
      setIsTextError("success");
      setValidateStatus("success");
    } else {
      if (verifyResult.resultError?.code == "otp_invalid") {
        setIsTextError("รหัสผ่านไม่ถูกต้อง");
      }
      if (verifyResult.resultError?.code == "otp_expired") {
        setIsTextError("รหัสผ่านหมดอายุ");
      }
      if (verifyResult.resultError?.code == "otp_used_already") {
        setIsTextError("รหัสนี้ถูกใช้งานไปแล้ว");
      }
      if (
        verifyResult.resultError?.code == "otp_invalid" &&
        invalidCount >= 3
      ) {
        setIsTextError("ท่านกรอก OTP เกินจำนวนครั้งที่กำหนด");
      }
      // setValidateStatus(verifyResult.resultError?.code);
    }
  };

  console.log("isTextError ==========>", isTextError);
  console.log("verifyResult ==========>", verifyResult);

  const navigate = useNavigate();

  useEffect(() => {
    otpRequest(stateCustomer.mobileNo);
  }, []);

  useEffect(() => {
    handleValidate();
    console.log("test");
  }, [verifyResult]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRegister-otp">
        {/* <div id="pageRegister-otp"> */}
        <main>
          <div className="wrapContent">
            <section id="pServayMain">
              <div className="bServay">
                <div className="bTitlePage">
                  <h1>ยืนยันตัวตน</h1>
                  <p>กรอกรหัสยืนยัน เพื่อยืนยันตัวตนในการลงทะเบียน</p>
                </div>
                <div className="bOtpMain">
                  <div className="bOtp">
                    <div className="bTitle">
                      <p>เราส่งรหัส OTP ไปที่ {stateCustomer.mobileNo}</p>
                    </div>
                    <div className="bFrom error">
                      {/* {JSON.stringify(tokenData.expireOn)} */}
                      <div className="bCountTime">
                        {tokenData.expireOn && (
                          <CountdownTimer
                            expTime={moment(tokenData.expireOn).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                            callback={() => console.log("รหัสผ่านหมดอายุ")}
                          />
                        )}
                      </div>
                      <div className="control-group otp">
                        <InputOtp
                          className="smsCode"
                          length={6}
                          value={otpPin}
                          onChange={(e) => {
                            otpVerify(e.value);
                            setOtpPin(e.value);
                          }}
                          integerOnly
                        />
                      </div>
                      <p>RefNo: {tokenData.refno}</p>
                      {isTextError && otpPin.length == 6 && isTextError !== "success"&& (
                        <p style={{ color: "red" }} className="tError">
                          {isTextError}
                        </p>
                      )}

                      <div className="bTextSend">
                        <p>
                          ไม่ได้รับรหัส?{" "}
                          <a
                            onClick={() => {
                              otpRequest(stateCustomer.mobileNo);
                              setOtpPin('')
                              setIsTextError("");
                              setInvalidCount(0)
                              for (let i = 0; i < otpError.length; i++) {
                                otpError[i].classList.remove("input-error");
                              }
                            }}
                          >
                            ส่งอีกครั้ง
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bStepBtn">
                  <div className="bStep">
                    <div className="bBullet">
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                    </div>
                    <p>มีอีก 2 ขั้นตอนให้คุณร่วมตอบกับเรา</p>
                  </div>
                  <div className="bBtn">
                    <button
                      disabled={
                        isTextError == "success" && otpPin?.length == 6 ? false : true
                      }
                      type="button"
                      onClick={() => navigate("/question")}
                      className="btn"
                    >
                      ยืนยัน
                    </button>
                    <button
                      className="btn outline"
                      onClick={() => navigate("/register")}
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Otp;
