import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import LuckydrawItem from "./LuckydrawItem";
import Banners from "../centerComponent/Banners";
import { LuckydrawList } from "services/Api/Module/Luckydraw";
import Pagination from "../centerComponent/Pagination";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import SubMenu from "../centerComponent/Submenu";
import { GetCustomerData } from "services/Api/Module/Customer";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;
const Luckydraw = () => {
  const [rewards, setLuckydraws] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoad , setIsLoad] = useState(true)
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getLuckydrawList = async () => {
    let _return = {
      status: false,
      result: null,
      rowCount: 0,
    };

    try {
      const { data } = await LuckydrawList({ params: { limit, offset } });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchLuckydrawList = async () => {
    try {
      const resData = await getLuckydrawList();
      setIsLoad(false);
      setLuckydraws(resData.result);
      setRowCount(resData.rowCount);
    } catch (err) {
      console.log(err);
    }
   
  };

  useEffect(() => {
    fetchLuckydrawList();
  }, [stateProfile, offset]);

  console.log("currentPoint ", stateProfile.currentPoint);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageLuckydraw-All">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <CollectpointHeader />
            {
              <>
                
                {!isLoad? 
                  rewards.length ? (
                    <section id="pContent">
                      <SubMenu />
                      <Banners currentPage="Lucky draw" />
                      <div className="bTitle">
                        <h2>Lucky Draw</h2>
                        <p>ใช้ Point แลกของที่คุณชอบ</p>
                      </div>
                      <div className="bBtn">
                        <a className="btn ">รางวัลทั้งหมด</a>
                        <a
                          className="btn outline"
                          onClick={() => navigate("/history-luckydraw")}
                        >
                          ประวัติการแลก
                        </a>
                      </div>
                      <div className="bGroupItem" data-event="matcha">
                        {rewards.map((item, index) => {
                          return <LuckydrawItem key={index} item={item} />;
                        })}
                      </div>
                      <Pagination
                        limit={limit}
                        offset={offset}
                        rowCount={rowCount}
                        onBack={(limit, offset) => {
                          setSearchParams({ offset });
                        }}
                        onNext={(limit, offset) => {
                          setSearchParams({ offset });
                        }}
                      />
                    </section>
                  ) : (
                    <section id="pComingSoon">
                      <p>รอติดตาม Lucky draw เร็ว ๆ นี้</p>
                    </section>
                  )
                
                :
                  ""
                }
              </>
            }
            <Menu />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Luckydraw;
