import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalConfirmLuckydraw = ({
  isOpen,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  const [modalRef, setModalRef] = useState(null);
  const [confirm, setConfirm] = useState(false);

  let btnCloseModal;
  const navigate = useNavigate();

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      listenerConfirmModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirmLuckydraw"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  const listenerConfirmModal = () => {
    btnCloseModal = document.getElementById("btnCloseModal1CF");
    btnCloseModal.addEventListener("click", () => {
      // onConfirm();
      // closeModal(); // ระวังบางหน้าไม่มี
      setConfirm(true);
    });
  };
  useEffect(() => {
    if (confirm) {
      onConfirm(confirm);
    }
  }, [confirm]);

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal1C");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    modalRef.close();
    onClose();
  };

  return (
    // <!-- Modal Confirm Lucky Draw -->
    <div
      className="bModal bCard"
      id="ModalConfirmLuckydraw"
      style={{ display: "none" }}
    >
      <div className="bInner">
        <div className="bDetails">
          <p className="tTitle">
            คุณยืนยันแลกสิทธิ์ลุ้น
            <br /> Lucky draw
          </p>
          <p className="tDetails">คุณต้องการใช้ลุ้นรางวัลกับเรา?</p>
        </div>
        <div className="bBtn">
          <a  className="btn" id="btnCloseModal1CF">
            ยืนยัน
          </a>
          <a className="btn outline btnModalCancel" id="btnCloseModal1C">
            ยกเลิก
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmLuckydraw;
