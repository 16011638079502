import React from "react";
import { useDispatch, useSelector } from "react-redux";

function FlavorEvent() {
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const { point, currentPoint } = stateProfile;
  return (
    <div className="bMatchaEvent" data-event="matcha">
      <div className="bPointGroup">
        <p>
          คุณมี Point <span>{currentPoint}</span> แบ่งออกเป็น
        </p>
      </div>
      <div className="bGroupFlavor">
        <article data-flavor="matcha">
          <p>
            <span>{point?.matcha}</span>
            ริช มัทฉะ
          </p>
        </article>
        <article data-flavor="original">
          <p>
            <span>{point?.chocolate}</span>
            ช็อกโกแลต
          </p>
        </article>
        <article data-flavor="strawberry">
          <p>
            <span>{point?.strawberry}</span>
            สตรอเบอร์รี
          </p>
        </article>
        <article data-flavor="darkchoc">
          <p>
            <span>{point?.black}</span>
            บิทเทอร์ ช็อกโกแลต
          </p>
        </article>
        <article data-flavor="whitechoc">
          <p>
            <span>{point?.white}</span>
            ไวท์ มิลค์
          </p>
        </article>
      </div>
      <div className="bOtherPoint">
        <p>
          และ Point อื่นๆ <span>{point?.other}</span>
        </p>
      </div>
    </div>
  );
}

export default FlavorEvent;
