import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Submenu = ({}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const isSchedualSticker =
    stateCurrentPoint.isSchedualSticker == 1 ? true : false;

  const setActive = (pages = [], path) => {
    if (pages.includes(path)) {
      return " active ";
    }
    return "";
  };

  return (
    <div className="bSubmenu" style={{overflow:"hidden"}}>
      <nav style={{width:"100%"}}>
        <a
          className={`scan ${setActive(["/reward"], pathname)}`}
          onClick={() => navigate("/reward")}
        >
          Rewards
        </a>
        <a
          className={`scan ${setActive(["/luckydraw"], pathname)}`}
          onClick={() => navigate("/luckydraw")}
        >
          Lucky Draw
        </a>
        {isSchedualSticker && (
          <a
            className={`scan ${setActive(["/character-collection"], pathname)}`}
            onClick={() => navigate("/character-collection")}
          >
            Character Collection
          </a>
        )}
      </nav>
    </div>
  );
};

export default Submenu;
