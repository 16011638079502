import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import Layouts from "layouts/Main";
import LoaderPage from "./components/loader/LoaderPage";

import AppRouter from "./appRouter"; // Router

import { setProfile } from "./features/ProfileSlice";
import { setRegister } from "./features/RegisterKlSlice";
import { GetSearchCustomerData } from "services/Api/Module/Customer";

// const devMode = true;
const devMode = false;  

function App() {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [checkLineProfile, setCheckLineProfile] = useState(false);
  const [checkProfile, setCheckProfile] = useState(false);

  let { isLoggedIn, liff } = useLiff();

  if (!isLoggedIn) {
    isLoggedIn = devMode; // mock true เอาไว้ dev
  }

  const getSearchCustomerByLineId = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await GetSearchCustomerData({ params: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;

        dispatch(setProfile(resData));
        setCheckProfile(true);
      }
    } catch (e) {
      console.log("log : ", e);
    }

    return _return;
  };

  const initialized = useRef(false);

  useEffect(() => {
    if (!isLoggedIn && devMode) return;
    (async () => {
      try {
        const profile = await liff.getProfile();

        if (profile) {
          if (!initialized.current) {
            initialized.current = true;

            const sub = liff.getDecodedIDToken().sub;
            const lineName = liff.getDecodedIDToken().name;
            const lineImage = liff.getDecodedIDToken().picture;

            let tmpObj = {
              lineUid: sub,
              lineName: lineName,
              lineImageUrl: lineImage,
            };

            const _rsData = getSearchCustomerByLineId({
              lineUid: sub,
            });

            _rsData.then((data) => {
              if (data.status === true && data.result) {
                if (data.result.isRegisSbSystem) {
                  // customer ที่มี data register แล้ว
                  tmpObj = {
                    ...tmpObj,
                    dataUser: data.result,
                    customerId: data.result.id,
                  };
                  dispatch(setProfile(tmpObj));
                  setCheckProfile(true);
                } else {
                  // customer ที่มี data แต่ยังไม่ register
                  tmpObj = {
                    ...tmpObj,
                    customerData: data.result,
                    customerId: data.result.id,
                  };
                  dispatch(setRegister(tmpObj));
                  setCheckProfile(false);
                }
              } else {
                tmpObj = {
                  ...tmpObj,
                };
                dispatch(setRegister(tmpObj));
                setCheckProfile(false);
              }
              setCheckLineProfile(true);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [liff, isLoggedIn, dispatch, stateProfile]);

  if (!devMode && !checkLineProfile) {
    return <LoaderPage />;
  }

  if (isLoggedIn || devMode) {
    return (
      <Layouts>
        <AppRouter isProfile={checkProfile} isState={stateProfile} />
      </Layouts>
    );
  }
}

export default App;
